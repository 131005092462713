import { component } from "picoapp";
import Glide from "@glidejs/glide";

export default component((node) => {
  new Glide(node, {
    type: "slider",
    perView: 3,
    gap: 60,
    peek: {
      before: 0,
      after: 240,
    },
    bound: true,
    dragThreshold: 60,
    breakpoints: {
      1280: {
        perView: 2,
        gap: 30,
        peek: {
          before: 0,
          after: 120,
        },
      },
      1024: {
        perView: 2,
        gap: 30,
        peek: {
          before: 0,
          after: 120,
        },
      },
      768: {
        perView: 1,
        gap: 30,
        peek: {
          before: 0,
          after: 80,
        },
      },
    },
  }).mount();
});
