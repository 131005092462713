import { component } from "picoapp";
import Glide from "@glidejs/glide";

export default component((node) => {
  const gap = node.getAttribute('data-gap') || 20;
  const peek = node.getAttribute('data-peek') || 0;

  new Glide(node, {
    type: "carousel",
    perView: 3,
    gap: gap,
    peek: {
      before: 0,
      after: 0,
    },
    breakpoints: {
      520: {
        gap: gap / 2,
        perView: 1,
        peek: {
          before: peek,
          after: peek,
        },
        focusAt: "center",
      },
    },
  }).mount();
});
