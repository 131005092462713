import Bugsnag from "@bugsnag/js";
import type { AlpineComponent } from "alpinejs";
import useShopifyAjax from "@/js/composables/useShopifyAjax";
import Cookies from "js-cookie";
// @ts-ignore
import { formatMoney } from "@shopify/theme-currency";

const { segmentTrack } = useAnalytics();

type Props = {
  product: any;
};

type State = {
  loading: boolean;
  giftProduct: string;
  handleSubmit: () => Promise<void>;
};

export function AnnualGift({ product }: Props): AlpineComponent<State> {
  const { addToCart, removeItem, goToCheckout } = useShopifyAjax();

  const variant = product.variants[0];

  return {
    loading: false,
    giftProduct: "",
    async handleSubmit() {
      if (this.giftProduct == "") return;

      this.loading = true;

      const cartPayload = {
        items: [
          {
            id: variant.id,
            quantity: 1,
            properties: {
              _annual_member_gift: this.giftProduct,
            },
            selling_plan: variant.selling_plan_allocations[0]?.selling_plan_id,
          },
        ],
      };

      try {
        await removeItem(variant.id);
        await addToCart(cartPayload);
        await segmentTrack("Product Added", {
          cart_id: Cookies.get("cart"),
          name: product.title,
          variant: variant.title,
          variant_id: variant.id,
          product_id: String(product.id),
          price: formatMoney(variant.price, "{{amount}}"),
          currency: "USD",
          quantity: 1,
          sku: variant.sku,
          url: window.location.href,
          value: formatMoney(variant.price, "{{amount}}"),
          product_type: product.type,
          source: "annual member gift",
        });
      } catch (error: any) {
        Bugsnag.notify(error);
      }

      goToCheckout();
    },
  };
}
