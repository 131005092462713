export function handleRangeSlider(CFform, node) {
  node.querySelectorAll("[data-range-slider]").forEach((el) => {
    // initialize
    CFform.cfForm.setFieldValue(
      el.getAttribute("data-range-slider"),
      el.value
    );
    // update on change
    el.addEventListener("change", (e) => {
      CFform.cfForm.setFieldValue(
        e.target.getAttribute("data-range-slider"),
        e.target.value
      );
    });
  });
}

export function updateStepHash(stepIndex) {
  // update hash
  if (history.pushState && stepIndex > 0) {
    history.pushState(null, null, `#${stepIndex}`);
  } else {
    history.pushState(null, null, " ");
  }
}

export function handleBrowserBackButton(CFform) {
  window.addEventListener(
    "popstate",
    function () {
      CFform.cfForm.goToPreviousStep(false);
    },
    false
  );
}
