// @ts-nocheck
import axios from "axios";
import { AlpineComponent } from "alpinejs";
import useTheme from "@composables/useTheme";

const SEARCH_TOPICS = [
  {
    title: "Dogs",
    url: "/search?q=dogs&type=product&options%5Bunavailable_products%5D=hide&options%5Bprefix%5D=last&options%5Bfields%5D=tag",
  },
  {
    title: "Cats",
    url: "/search?q=cats&type=product&options%5Bunavailable_products%5D=hide&options%5Bprefix%5D=last&options%5Bfields%5D=tag",
  },
  {
    title: "Anxiety in Dogs",
    url: "/search?q=anxiety+dog&type=product&options%5Bunavailable_products%5D=hide&options%5Bprefix%5D=last&options%5Bfields%5D=tag",
  },
  {
    title: "Dog products",
    url: "/search?q=dog&type=product&options%5Bunavailable_products%5D=hide&options%5Bprefix%5D=last&options%5Bfields%5D=tag",
  },
  {
    title: "Cat products",
    url: "/search?q=cat&type=product&options%5Bunavailable_products%5D=hide&options%5Bprefix%5D=last&options%5Bfields%5D=tag",
  },
];

export function Search(): AlpineComponent {
  const { routes } = useTheme();
  const SEARCH_SETTINGS =
    "&resources[type]=product,collection&resources[options][unavailable_products]=hide&resources[options][fields]=title,tag,product_type,variants.title";

  return {
    isShowSuggestions: false,
    searchQuery: "",
    results: [],
    topics: SEARCH_TOPICS,
    loading: false,
    isOpen: false,
    init() {
      this.$watch("searchQuery", this.search.bind(this));
      this.$watch("isOpen", (val) => {
        if (!val) {
          return (this.isShowSuggestions = false);
        }
      });
    },
    get isNoResults() {
      return (
        !this.loading &&
        !this.results.length &&
        !this.isInvalidQuery &&
        this.searchQuery
      );
    },
    get isInvalidQuery() {
      return this.inputValidation(this.searchQuery);
    },
    get isShowPopularTopics() {
      return !this.searchQuery && !this.results.length;
    },
    inputValidation(value) {
      const regex = /^[a-zA-Z0-9\s]*$/;
      return !regex.test(value);
    },
    async search(query: string): Promise<void> {
      if (this.isInvalidQuery) {
        return;
      }
      if (!query.length) {
        this.results = [];
        return;
      }

      try {
        this.loading = true;

        const { data } = await axios.get(
          `${routes.root_url}search/suggest.json?q=${query}${SEARCH_SETTINGS}`
        );

        this.results =
          [
            ...data?.resources?.results?.collections,
            ...data?.resources?.results?.products,
          ] || [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.searchQuery = "";
      this.results = [];
    },
    focusSearch() {
      this.isOpen = !this.isOpen;
      this.$nextTick(() => this.$refs.input.focus());
    },
  };
}
