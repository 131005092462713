/* eslint-disable no-undef */

export function trackCompletedStep(node) {
  const { segmentTrack } = useAnalytics();
  const assessmentTitle = node.getAttribute("data-quiz-title");
  const previousStepTitle = node.getAttribute("data-prev-step-title");
  const stepCounterElement = document.querySelector(".cf-step-counter");
  let stepNumber = 0;
  if (stepCounterElement) {
    const stepText = stepCounterElement.innerText;
    const matches = stepText.match(/Step (\d+)/);
    if (matches) {
      stepNumber = parseInt(matches[1], 10);
    }
  }

  segmentTrack("Assessment Step Completed", {
    assessment_name: assessmentTitle,
    step_name: previousStepTitle,
    step_number: stepNumber - 1,
  });

  if (typeof dataLayer !== "undefined") {
    if (CF.customer.get("quiz_type")) {
      dataLayer.push({
        quizCategory: `Quiz ${assessmentTitle}`,
        quizAction: "Step changed",
        quizStep: previousStepTitle,
        event: "trackCompletedStep",
      });
    }
  }
}
