import type { AlpineComponent } from "alpinejs";
import type { SegmentEvent } from "@segment/analytics-next";

export function Segment(Alpine: AlpineComponent<any>) {
  const trackEvent = (event: string | SegmentEvent, properties?: any) => {
    if (window?.analytics) {
      window.analytics.track(event, properties);
    }
  };

  const trackPageView = () => {
    if (window?.analytics) {
      window.analytics.page("Page Viewed");
    }
  };

  Alpine.magic("segmentTrack", () => trackEvent);
  Alpine.magic("segmentPage", () => trackPageView);
}
