import { component } from "picoapp";

export default component((node) => {
  const toggle = node.querySelector("[data-toc-toggle]");
  const chevron = node.querySelector("[data-toc-toggle] svg");
  const content = node.querySelector("[data-toc-content]");
  const links = node.querySelectorAll("[data-toc-content] a");
  const isMobile = window.matchMedia('(max-width: 767px)');

  if (toggle) {
    toggle.addEventListener("click", (e) => {
      e.preventDefault();

      if (isMobile) {
        if (content.classList.contains("hidden")) {
          content.classList.remove("hidden");
          chevron.classList.add("transform", "rotate-180");
        } else {
          content.classList.add("hidden");
          chevron.classList.remove("transform", "rotate-180");
        }
      }
    });
  }

  links.forEach((link) => {
    link.addEventListener("click", () => {
      if (isMobile) {
        content.classList.add("hidden");
      }
    });
  });
});
