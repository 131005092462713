import { CartAPI } from "@/js/composables/useCart/api";
import type { Cart } from "@/js/composables/useCart/types";

export interface State {
  state: Cart | undefined;
  init?(): Promise<void>;
  fetchCart: () => Promise<void>;
}

const api = new CartAPI();

const fetchCart = async (): Promise<Cart> => {
  return api.fetchCart();
};

export const cart: State = {
  state: undefined,
  async fetchCart() {
    this.state = await fetchCart();
  },
  async init() {
    this.fetchCart();
  },
};
