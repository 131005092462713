import type { State as UIStore } from "@/alpine/stores/ui";
import {
  PROD_RECOMMENDED_PRODUCTS,
  RecommendedProducts,
  STG_RECOMMENDED_PRODUCTS,
} from "@/data/quiz-recommendation";
import { CartItem } from "@/js/composables/useCart/types";
import type { Product } from "@/vue/apps/product/types";
import Bugsnag from "@bugsnag/js";
// @ts-ignore
import { formatMoney } from "@shopify/theme-currency";
import type { AlpineComponent } from "alpinejs";
import Cookies from "js-cookie";

// TODO: Improve typing
export function QuizRecommendProduct(state: any): AlpineComponent<any> {
  return {
    ...state,
    quizResult: null,
    recommendedProducts: [] as RecommendedProducts[],
    quantity: 1,
    loading: false,
    isAdded: false,
    recommendedProduct: null,

    async init() {
      await window.CF.customerReady(() => {
        this.quizResult = window.CF.customer.data;
        this.initRecommendedProducts();
        this.setup();
      });
    },

    initRecommendedProducts() {
      this.recommendedProducts = useTheme().IS_STAGING
        ? STG_RECOMMENDED_PRODUCTS
        : PROD_RECOMMENDED_PRODUCTS;
    },

    setup() {
      if (!this.products.length || !this.quizResult) {
        console.error("Missing products or quiz result");
        return;
      }

      const quizWeight = (
        this.quizResult?.rec_dog_flea_q_1 || ""
      ).toLowerCase();
      const quizHeartworm = (
        this.quizResult?.rec_dog_flea_q_2 || ""
      ).toLowerCase();

      if (!quizWeight || !quizHeartworm) return;

      const recommendedProduct = this.recommendedProducts.find(
        ({ weight }: { weight: string }) => quizWeight.includes(weight)
      );

      if (recommendedProduct) {
        const variantId = quizHeartworm.includes("yes")
          ? recommendedProduct.heartwormVariantId
          : recommendedProduct.fleaVariantId;

        this.recommendedProduct = this.generateProduct(
          variantId,
          this.products
        );
      }
    },

    generateProduct(targetVariantId: number, products: Product[]) {
      const filteredProducts = products.filter((product) =>
        product.variants.some((variant) => variant.id === targetVariantId)
      );

      if (filteredProducts.length === 0) return null;

      const targetProduct = filteredProducts.map((product) => {
        const targetVariant = product.variants.find(
          (variant) => variant.id === targetVariantId
        );

        const variantIndex = product.variants.findIndex(
          (variant) => variant.id === targetVariantId
        );

        return {
          id: product.id,
          title: product.title,
          productType: product.type,
          handle: product.handle,
          image:
            targetVariant?.featured_image?.src ||
            product?.images[variantIndex] ||
            product?.images[0],
          variant: {
            ...targetVariant,
          },
        };
      });

      return Object.assign({}, ...targetProduct);
    },

    formatPrice(amount: number, discountAmountPercentage = 0) {
      if (!amount) return;
      if (discountAmountPercentage === 0) return formatMoney(amount);
      const amountWithDiscount = amount * (1 - discountAmountPercentage / 100);

      return formatMoney(amountWithDiscount);
    },

    async addBundleToCart() {
      try {
        this.loading = true;
        const addedProducts = await this.addItemsToCart();
        // Analytics
        if (addedProducts?.length) {
          this.trackAddedProducts(addedProducts);
        }

        // Discount code
        if (this.discountCode?.length) {
          this.handleDiscountCode();
          return;
        }

        // Cart drawer
        if (addedProducts?.length) {
          Alpine.store<UIStore>("ui").show({ items: addedProducts });
        }
      } catch (error: any) {
        Bugsnag.notify(error);
      } finally {
        this.loading = false;
      }
    },

    handleDiscountCode() {
      const redirect = this.isLoggedIn ? "/cart" : "/account/register";
      const url = `${window.location.origin}/discount/${this.discountCode}?redirect=${redirect}`;
      window.location.assign(url);
    },

    async addItemsToCart(): Promise<CartItem[] | undefined> {
      const productType = this.recommendedProduct.productType.toLowerCase();
      const isRx = productType === "rx";
      const isOTC = productType === "otc";

      try {
        await useCart().addItemToCart({
          items: [
            {
              id: this.recommendedProduct.variant.id,
              quantity: 1,
              properties: {
                _animal_type: "dog or cat",
                _marketing_banners: true,
                ...(isRx && {
                  _purchase_rx: true,
                  _request_rx: true,
                }),
                ...(isOTC && {
                  _otc: true,
                }),
              },
            },
          ],
          attributes: {
            purchase_rx: isRx ? true : undefined,
            request_rx: isRx ? true : undefined,
            get_rx: isRx ? true : undefined,
          },
        });

        return useCart().state.value?.items.filter(
          (item) => item.variant_id === this.recommendedProduct.variant.id
        );
      } catch (error: any) {
        Bugsnag.notify(error);
      }
    },

    trackAddedProducts(addedProducts: CartItem[]) {
      if (window.analytics) {
        addedProducts.forEach((item) => {
          window.analytics?.track("Product Added", {
            cart_id: Cookies.get("cart"),
            variant_id: item.id,
            currency: "USD",
            quantity: item.quantity,
            url: window.location.href,
            source: "quiz recommendation",
          });
        });
      }
    },
  };
}
