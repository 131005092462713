import type { State as CartStore } from "@/alpine/stores/cart/index";
import type { State as UIStore } from "@/alpine/stores/ui";
import type { Product } from "@/vue/apps/product/types";
import type { AlpineComponent } from "alpinejs";

type State = {
  loading: boolean;
  modalOpen: boolean;
  products: Product[] | null;
  render: () => void;
};

type Props = {
  index: string;
  formId: string;
  redirectUrl?: string;
};

const waitForElement = (selector: string, callback: Function) => {
  new MutationObserver((_, observer) => {
    if (document.querySelector(selector)) {
      observer.disconnect();
      callback();
    }
  }).observe(document.body, { childList: true, subtree: true });
};

const initPresidio = () => {
  if (!window.quizKitScriptLoaded) {
    window.quizKitScriptLoaded = true;
    const script = document.createElement("script");
    script.defer = true;
    script.setAttribute(
      "src",
      "https://d38jc50suw8dg3.cloudfront.net/current/static/bundle.min.js"
    );
    document.head.appendChild(script);
    const appStyles = document.createElement("link");
    appStyles.type = "text/css";
    appStyles.rel = "stylesheet";
    appStyles.href =
      "https://d38jc50suw8dg3.cloudfront.net/current/static/main.min.css";
    appStyles.media = "print";
    appStyles.onload = function () {
      // @ts-ignore
      this.media = "all";
    };
    document.head.appendChild(appStyles);
  }
};

export function PopupPresidio(props: Props): AlpineComponent<State> {
  const { index, formId, redirectUrl } = props;

  return {
    loading: false,
    modalOpen: false,
    products: null,
    init() {
      this.$watch("modalOpen", (value) => value && initPresidio());

      window.document.addEventListener(`dp:${formId}-${index}`, () => {
        this.modalOpen = true;
      });

      window.document.addEventListener(
        "quizKitResultsPageLoaded",
        (e: Event) => {
          const customEvent = e as CustomEvent;
          if (!customEvent.detail?.recommendedProducts) return;
          this.products = customEvent.detail?.recommendedProducts;
          waitForElement('[data-section-id^="products_"]', this.render);
        }
      );

      window.document.addEventListener(
        "quizKitAddToCart",
        (e: Event) => {
          const customEvent = e as CustomEvent;

          if (!this.products || !customEvent.detail.length) return;

          customEvent.detail.forEach((lineItem: any) => {
            const product: any = this.products?.find((p) =>
              p.variants.find((v) => v.id === lineItem.id)
            );

            if (product) {
              const tags = product.tags?.split(",");
              const isDog = tags?.includes("dog");
              const isCat = tags?.includes("cat");
              const isRx = product.product_type === "Rx";

              lineItem.properties = {
                ...lineItem.properties,
                _animal_type: isDog ? "dog" : isCat ? "cat" : "dog or cat",
                ...(isRx && {
                  _purchase_rx: true,
                  _request_rx: true,
                }),
                ...(!isRx && {
                  _otc: true,
                }),
              };
            }
          });
        },
        false
      );

      document.addEventListener(
        "quizKitAddToCartSuccess",
        async () => {
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          const { state: cart, execute: fetchProducts } = useCart();
          await fetchProducts();
          const cartProducts = cart.value?.items ?? [];
          const addedProducts = cartProducts.filter((item) =>
            this.products?.some((p) => p.variants.some((v) => v.id === item.id))
          );
          this.modalOpen = false;
          if (!addedProducts?.length) return;
          Alpine.store<CartStore>("cart").fetchCart();
          Alpine.store<UIStore>("ui").show({ items: addedProducts });
        },
        false
      );
    },
    destroy() {
      // Remove all event listeners
      window.document.removeEventListener(`dp:${formId}-${index}`, () => {
        this.modalOpen = true;
      });
    },
    render() {
      // Insert div before the first h2 in the section
      const insertDiv = (section: Element) => {
        const lastChildDiv = section.querySelector("h2");
        const savemob = section.querySelector(".save20mob");
        const template = document.getElementById(
          "presidio-template-content"
        ) as HTMLTemplateElement | null;

        if (!savemob && lastChildDiv && template) {
          lastChildDiv.insertAdjacentHTML("beforebegin", template.innerHTML);
        }
      };

      document
        .querySelectorAll('[data-section-id="products_3"]')
        ?.forEach(insertDiv);

      // Move button to the right place
      const moveButton = (section: Element) => {
        const lastChildDiv = section.querySelector("div:last-child");
        const button = lastChildDiv?.querySelector("button");

        if (button) {
          const firstChildDiv = section.querySelector("div:first-child");
          const h2 = firstChildDiv?.querySelector("h2");
          button.innerText = "Add to cart";
          if (h2) {
            h2.insertAdjacentElement("afterend", button);
          }
        }
      };

      document
        .querySelectorAll('[data-section-id^="products_"]')
        ?.forEach(moveButton);
    },
  };
}
