import { component } from "picoapp";

export default component(() => {
  const details = document.querySelectorAll("details");

  details.forEach((detail) => {
    detail.addEventListener("toggle", toggleOpenOneOnly);
  });

  function toggleOpenOneOnly() {
    if (this.open) {
      details.forEach((detail) => {
        if (detail != this && detail.open) {
          detail.open = false;
        }
      });
    }
  }
});
