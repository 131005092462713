import type { Customer, CustomerAccessToken } from "@/graphql/generated/graphql-operations";
import type { ApiResponse, Subscription } from "@/types/subscription";
import Bugsnag from "@bugsnag/js";
import type { AlpineComponent } from "alpinejs";
import axios from "axios";

interface CustomerStore {
  customer: Customer;
  customerAccessToken: CustomerAccessToken;
}

interface State {
  isSubscriber: boolean | null;
  subscription: Subscription | null;
  currentPage: string;
  fetchSubscriptionStatus: () => Promise<void>;
  redirectToCurrentPage: () => void;
  init: () => void;
}

const PAGES = {
  NON_MEMBERS: '/pages/embrace-pet-insurance',
  MEMBERS: '/pages/embrace-pet-insurance-for-dutch-members'
};

export function Embrace(): AlpineComponent<State> {
  const { get: getCustomer } = useLocalStorage();
  const { customer } = window.theme;
  const customerStore = getCustomer<CustomerStore>("customer");
  const { EMR_BASE_URL } = useTheme();

  const EMRMembership = axios.create({
    baseURL: `${EMR_BASE_URL}/MembershipV0`,
    headers: {
      Authorization: `Token ${customerStore?.customerAccessToken?.accessToken}`,
    },
  });

  async function fetchMembershipData(): Promise<ApiResponse | undefined> {
    if (!customer?.id) return;

    try {
      const { data } = await EMRMembership.get<ApiResponse>(
        `/membership?shopify_customer_id=${customer.id}`
      );
      return data;
    } catch (error: any) {
      Bugsnag.notify(error);
    }
  }

  return {
    isSubscriber: null,
    subscription: null,

    get currentPage(): string {
      return this.isSubscriber ? PAGES.MEMBERS : PAGES.NON_MEMBERS;
    },

    redirectToCurrentPage(): void {
      const targetPage = this.currentPage;
      if (window.location.pathname !== targetPage) {
        window.location.href = targetPage;
      }
    },

    async fetchSubscriptionStatus(): Promise<void> {
      try {
        const result = await fetchMembershipData();
        this.isSubscriber = result?.membership?.active || false;
        this.subscription = result?.membership?.subscription || null;
      } catch (error) {
        console.error(error);
      }
    },

    init(): void {
      this.$watch('isSubscriber', () => {
        if (this.isSubscriber !== null) {
          this.redirectToCurrentPage();
        }
      });
      this.fetchSubscriptionStatus();
    }
  };
}