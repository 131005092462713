import type { State as CartStore } from "@/alpine/stores/cart/index";
import type { State as UIStore } from "@/alpine/stores/ui";
import type { CartItem } from "@/js/composables/useCart/types";
import type { Product } from "@/vue/apps/product/types";
import Bugsnag from "@bugsnag/js";
import type { AlpineComponent } from "alpinejs";
import Cookies from "js-cookie";

// Interfaces
interface Props {
  products: Product[];
  isAutoApplyDiscount?: boolean;
  discount?: string;
}

interface State extends Props {
  isLoading: boolean;
  addBundleToCart: () => Promise<void>;
}

// Utility functions
const redirectToCart = (discountCode?: string) => {
  const path = discountCode
    ? `/discount/${discountCode}?redirect=/cart`
    : "/cart";
  window.location.assign(path);
};

const trackProductAdded = (variantId: string | number) => {
  window?.analytics?.track("Product Added", {
    cart_id: Cookies.get("cart"),
    variant_id: variantId,
    currency: "USD",
    quantity: 1,
    url: window.location.href,
    source: "bundle",
  });
};

const getProductProperties = (type: string) => ({
  _animal_type: "dog or cat",
  _marketing_banners: true,
  ...(type === "Rx" && { _purchase_rx: true, _request_rx: true }),
  ...(type === "OTC" && { _otc: true }),
});

// Main component
export function BundleCart(props: Props): AlpineComponent<State> {
  const { products, discount, isAutoApplyDiscount = false } = props;
  const { addItemToCart } = useCart();

  const createCartItems = () => {
    return products.flatMap(({ variants, type }) => {
      const id = variants.find((v) => v.available)?.id;
      if (!id) return [];
      trackProductAdded(id);
      const properties = getProductProperties(type);
      return [{ id, quantity: 1, properties }];
    });
  };

  const handleSuccessfulAdd = (addedProducts: CartItem[]) => {
    if (discount && isAutoApplyDiscount) {
      redirectToCart(discount);
    } else {
      Alpine.store<CartStore>("cart").fetchCart();
      Alpine.store<UIStore>("ui").show({
        items: addedProducts,
      });
    }
  };

  const addBundleToCart = async function (this: State) {
    try {
      this.isLoading = true;

      const items = createCartItems();
      await addItemToCart({ items });

      const addedProducts = useCart().state.value?.items.filter((item) =>
        this.products?.some((p) => p.variants.some((v) => v.id === item.id))
      );

      if (addedProducts?.length) {
        handleSuccessfulAdd(addedProducts);
      }
    } catch (error: any) {
      Bugsnag.notify(error);
    } finally {
      this.isLoading = false;
    }
  };

  return {
    products,
    discount,
    isAutoApplyDiscount,
    isLoading: false,
    addBundleToCart,
  };
}
