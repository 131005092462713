export const STG_RECOMMENDED_PRODUCTS: RecommendedProducts = [
  {
    weight: "4.4-6",
    fleaVariantId: 41855342084249,
    heartwormVariantId: 41855323537561,
  },
  {
    weight: "6.1-12",
    fleaVariantId: 41855342149785,
    heartwormVariantId: 41855323570329,
  },
  {
    weight: "12-25",
    fleaVariantId: 41855342018713,
    heartwormVariantId: 41855323603097,
  },
  {
    weight: "25.1-50",
    fleaVariantId: 41855342051481,
    heartwormVariantId: 41855323635865,
  },
  {
    weight: "50.1-100",
    fleaVariantId: 41855342117017,
    heartwormVariantId: 41855323668633,
  },
] as const;

export const PROD_RECOMMENDED_PRODUCTS: RecommendedProducts = [
  {
    weight: "4.4-6",
    fleaVariantId: 43261427876016,
    heartwormVariantId: 43261418078384,
  },
  {
    weight: "6.1-12",
    fleaVariantId: 43261427908784,
    heartwormVariantId: 43261418111152,
  },
  {
    weight: "12-25",
    fleaVariantId: 43261427941552,
    heartwormVariantId: 43261418143920,
  },
  {
    weight: "25.1-50",
    fleaVariantId: 43261427974320,
    heartwormVariantId: 43261418176688,
  },
  {
    weight: "50.1-100",
    fleaVariantId: 43261428007088,
    heartwormVariantId: 43261418209456,
  },
] as const;

interface Product {
  weight: string;
  fleaVariantId: number;
  heartwormVariantId: number;
}

export type RecommendedProducts = Product[];
