import { RechargeWorker } from "@/workers";
import Bugsnag from "@bugsnag/js";
import type { AlpineComponent } from "alpinejs";

type Props = {
  productHandle: string;
  discount?: string;
  discountAmount?: number;
};

type State = {
  loading: boolean;
  error: boolean;
  formattedDiscountAmount: number | undefined;
  subscriptionId: string | undefined;
  rechargeCustomerId: string | undefined;
  formerPlanPrice: number;
  newPlanPrice: number;
  segmentAnalytics: () => Promise<void>;
  upgradeMembership: () => Promise<void>;
  performUpgrade: (variantId: string) => Promise<void>;
  handleError: (error: any) => void;
};

export function Membership({
  productHandle,
  discount,
  discountAmount,
}: Props): AlpineComponent<State> {
  const { subscription } = useSubscription();
  const { segmentTrack } = useAnalytics();

  return {
    // State
    loading: false,
    error: false,

    // Computed
    get formattedDiscountAmount() {
      return discount && discountAmount ? discountAmount / 100 : undefined;
    },
    get subscriptionId() {
      return String(subscription.value?.subscription_id);
    },
    get rechargeCustomerId() {
      return String(subscription.value?.recharge_customer_id);
    },
    get formerPlanPrice() {
      return Number(subscription.value?.price) || 0;
    },
    get newPlanPrice() {
      return (
        this.formerPlanPrice * 12 * (1 - (this.formattedDiscountAmount || 0))
      );
    },

    // Methods
    async segmentAnalytics() {
      const trackData = {
        plan_movement: "upgrade",
        former_plan_name: "Unlimited Vet Advice",
        new_plan_name: "Unlimited Vet Advice - Annual Membership",
        former_plan_price: `$${this.formerPlanPrice.toFixed(2)}`,
        new_plan_price: `$${this.newPlanPrice.toFixed(2)}`,
        origin_source: "Upgrade to annual",
      };

      await Promise.all([
        segmentTrack("actionMembershipUpgraded"),
        segmentTrack("Membership Updated", trackData),
      ]);
    },

    async upgradeMembership() {
      this.loading = true;
      this.error = false;

      try {
        const variantId = await getVariantId(productHandle);
        await this.performUpgrade(variantId);
        await this.segmentAnalytics();
        redirectToAccount({
          annual_upgraded: "true",
          discount_amount: String(this.formattedDiscountAmount),
        });
      } catch (error: any) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },

    async performUpgrade(variantId: string) {
      const result = await RechargeWorker.subscription.upgrade.$post({
        json: {
          variant_id: variantId,
          subscription_id: this.subscriptionId,
          rc_customer_id: this.rechargeCustomerId,
          discount,
        },
      });
      if (!result.ok) throw new Error("Upgrade failed");
    },

    handleError(error: any) {
      this.error = true;
      Bugsnag.notify(error);
      console.error("Membership upgrade failed:", error);
    },
  };
}

// Helper functions
const getShopifyProduct = async (handle: string) => {
  try {
    const { data } = await axios.get(
      `${window.location.origin}/products/${handle}.json`
    );

    return data?.product;
  } catch (error: any) {
    Bugsnag.notify(error);
    throw new Error("Failed to fetch Shopify product");
  }
};

const getVariantId = async (productHandle: string) => {
  const product = await getShopifyProduct(productHandle);
  const variantId = product?.variants[0]?.id;
  if (!variantId) throw new Error("Product not found");
  return variantId;
};

const redirectToAccount = (params?: Record<string, string>): void => {
  const searchParams = new URLSearchParams(params);
  window.location.assign(`/account#/?${searchParams.toString()}`);
};
